import { useEffect, useState } from 'react'
import ItemSelector from './Items'
import useLocalStorage from '../../hook/useLocalStorage'
import {v4 as uuid} from 'uuid'
import {KEY} from '../../constants'


export default function Prayers() {
  const [data, setData] = useLocalStorage(KEY.PRAYERS, [])
  
  
  const add = (event) => {
    event.preventDefault()
    
    const form = event.target
    
    const formData = new FormData(form)
    
    const values = {}
    
    // Collect all values from the multiple select
    formData.forEach((value, key) => {
      if (!values[key]) {
        values[key] = []
      }
      values[key].push(value)
    })
    
    const newPrayer = {
      ...values,
      created: new Date().toISOString(),
      id: uuid(),
    }
    
    const newData = [...data, newPrayer]
    
    setData(newData)
    
    form.reset()
    
    return false
  }
  
  
  return (
    <div>
      <h2> Record a Prayer </h2>
      
      <form className='flex row prayer selectors' onSubmit={add}>
        <ItemSelector
          size={10}
          storageKey={KEY.PEOPLE}
          title="Person"
        />
        
        <ItemSelector
          size={10}
          storageKey={KEY.TOPICS}
          title="Topic"
        />
        
        <ItemSelector
          size={10}
          storageKey={KEY.EVENTS}
          title="Event"
        />
        
        <div>
          <button type="submit">Add</button>
        </div>
      </form>
    </div>
  )
}