import {useEffect, useState} from 'react'
import useLocalStorage from '../../hook/useLocalStorage'
import {
  filterPattern,
  isDigit,
} from '../../lib/helpers'


export default function ItemSelector({
  size=5,
  storageKey,
  title,
}) {
  const [data] = useLocalStorage(storageKey, [])

  const [filter, setFilter] = useState()
  const [items, setItems] = useState([])
  const [shortcuts, setShortcuts] = useState([])


  useEffect(() => {
    if (data?.length) {
      const indexedTopics = data.map((person, index) => ({ ...person, index: index }))
      
      if (filter?.length) {
        const pattern = filterPattern(filter)
        const filteredItems = indexedTopics.filter(person => pattern.test(person.name))
        
        filteredItems.sort((a, b) => a.name.localeCompare(b.name))
        
        setItems(filteredItems)
      } else {
        setItems(indexedTopics.sort((a, b) => a.name.localeCompare(b.name)))
      }
    }
  }, [data, filter])


  useEffect(() => {
    const newShortcuts = []
    
    data.forEach(item => {
      item.name.split(' ').forEach(word => {
        const letter = word.charAt(0).toUpperCase()

        if (!newShortcuts.includes(letter) && !isDigit(letter)) newShortcuts.push(letter)
      })
    })
    
    newShortcuts.sort()
    
    setShortcuts(newShortcuts)
  }, [data])
  
  
  return <div>
    <h3>{title}</h3>
    
    {shortcuts && shortcuts.map((shortcut, index) => 
      <button
        className='shortcut'
        key={index}
        onClick={()=>setFilter((filter?filter:'')+shortcut)}
      >
        {shortcut}
      </button>
    )}
    
    {filter? <button className='plain' onClick={()=>setFilter('')}>&#x1F504;</button>: null}
    
    <br />
    
    <select multiple name={storageKey} size={size}>
      <option value={0}>None</option>
      
      {items.map((item, index) => {
        return <option key={index} value={item.id}>{item.name}</option>
      })}
    </select>
  </div>
}