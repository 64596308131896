import React from 'react'

import logo from './img/proxima.png'


export default function Header() {
  return <header>
    <img src={logo} alt="" height={48} />
    
    <h1>
      Deisis
    </h1>
  </header>
}
