import React from 'react'
import DataShare from './com/DataShare'
import Events from './com/Proxima/Events'
import Footer from './com/Footer'
import Generic from './com/Proxima/Generic'
import Header from './Header'
import Prayers from './com/Prayers'
import People from './com/Proxima/People'
import Topics from './com/Proxima/Topics'
import {HREFProvider, Scene} from './lib/HREFContext'
import {KEY, STATE, VIEW} from './constants'


import './App.css'


export default function App() {
  return <HREFProvider>
    <Header />
    
    <main>
      <Scene name={STATE.APP}>
        <Scene element={<></>} name={STATE.PROXIMA}
        >
          <Scene
            element={<Generic.Main
              addPath={VIEW.APP.PROXIMA.PEOPLE.ADD.path}
              editLink={VIEW.APP.PROXIMA.PEOPLE.EDIT.path}
              queryKey={'personIndex'}
              storageKey={KEY.PEOPLE}
              title="People"
            />}
            name={STATE.PEOPLE} 
          >
            <Scene
              element={<Generic.Add
                addPath={VIEW.APP.PROXIMA.PEOPLE.ADD.path}
                storageKey={KEY.PEOPLE}
              />}
              name={STATE.ADD}
            />

            <Scene
              element={<People.Edit
                editLink={VIEW.APP.PROXIMA.PEOPLE.EDIT.path}
                storageKey={KEY.PEOPLE}
              />}
              name={STATE.EDIT}
            />
          </Scene>
            
          <Scene
            element={<Generic.Main
              addPath={VIEW.APP.PROXIMA.TOPICS.ADD.path}
              editLink={VIEW.APP.PROXIMA.TOPICS.EDIT.path}
              queryKey={'topicIndex'}
              storageKey={KEY.TOPICS}
              title="Topics"
            />}
            name={STATE.TOPICS}
          >
            <Scene
              element={<Generic.Add
                addPath={VIEW.APP.PROXIMA.TOPICS.ADD.path}
                storageKey={KEY.TOPICS}
              />}
              name={STATE.ADD}
            />
            
            <Scene
              element={<Topics.Edit
                editLink={VIEW.APP.PROXIMA.TOPICS.EDIT.path}
                storageKey={KEY.TOPICS}
              />}
              name={STATE.EDIT}
            />
          </Scene>
            
          <Scene
            element={<Generic.Main 
              addPath={VIEW.APP.PROXIMA.EVENTS.ADD.path}
              editLink={VIEW.APP.PROXIMA.EVENTS.EDIT.path}
              queryKey={'eventIndex'}
              storageKey={KEY.EVENTS}
              title="Events"
            />}
            name={STATE.EVENTS}
          >
            <Scene
              element={<Generic.Add
                addPath={VIEW.APP.PROXIMA.EVENTS.ADD.path}
                storageKey={KEY.EVENTS}
              />}
              name={STATE.ADD}
            />
            
            <Scene
              element={<Events.Edit
                editLink={VIEW.APP.PROXIMA.EVENTS.EDIT.path}
                storageKey={KEY.EVENTS}
              />}
              name={STATE.EDIT}
            />
          </Scene>
        </Scene>
        
        <Scene
          element={<Prayers.Main />}
          name={STATE.PRAYERS}
        />
        
        <Scene
          element={<DataShare />}
          name={STATE.DATA}
        />
      </Scene>
    </main>
    
    <Footer />
  </HREFProvider>
}