import {useEffect, useState} from 'react'
import { useHREF } from '../../../lib/HREFContext'
import useLocalStorage from '../../../hook/useLocalStorage'
import {KEY, VIEW} from '../../../constants'
import {v4 as uuid} from 'uuid'


export default function EventEdit() {
  const {eventIndex, goto} = useHREF()
  
  const [events, setEvents] = useLocalStorage(KEY.EVENTS, [])
  
  const [event, setEvent] = useState()
  
  
  const update = e => {
    e.preventDefault()
    
    const form = e.target
    const formData = new FormData(form)
    const name = formData.get('name')
    const updated = (new Date()).toISOString()
    const end = formData.get('end')
    const start = formData.get('start')
    
    const newEvent = [...events]
    
    const priorEvent = newEvent[+eventIndex]
    
    newEvent[+eventIndex] = {
      id: uuid(),
      ...priorEvent,
      name,
      start,
      end,
      updated,
    }
    
    setEvents(newEvent)
    
    goto(VIEW.APP.PROXIMA.EVENTS.path)
    
    return false
  }
  
  
  useEffect(() => {
    if ((+eventIndex || +eventIndex===0) && events?.length) {
      setEvent(events[+eventIndex])
    }
  }, [eventIndex, events])
  
  
  return <div>
    Event #{eventIndex}
    
    <form className='flex col' onSubmit={update}>
      <label>
        Name
        <input autoFocus name="name" type="text" defaultValue={event?.name} />
      </label>
      
      <label>
        Start
        <input name="start" type="datetime-local" defaultValue={event?.start} />
      </label>
      
      <label>
        End
        <input name="end" type="datetime-local" defaultValue={event?.end} />
      </label>
      
      <br />
      
      <button type="submit">Update</button>
    </form>
  </div>
}