const KEY = {
  PEOPLE: 'people',
  PRAYERS: 'prayers',
  PROXIMA: 'proxima',
  TOPICS: 'topics',
  EVENTS: 'events',
}


const STATE = {
  ADD: 'ADD',
  APP: 'APP',
  DATA: 'DATA',
  EDIT: 'EDIT',
  EVENTS: 'EVENTS',
  LIST: 'LIST',
  PRAYERS: 'PRAYERS',
  PROXIMA: 'PROXIMA',
  PEOPLE: 'PEOPLE',
  TOPICS: 'TOPICS',
}


const VIEWS = {
  APP: {
    DATA: STATE.DATA,
    
    PROXIMA: {
      PEOPLE: {
        LIST: STATE.LIST,
        ADD: STATE.ADD,
        EDIT: STATE.EDIT,
      },
      
      TOPICS: {
        LIST: STATE.LIST,
        ADD: STATE.ADD,
        EDIT: STATE.EDIT,
      },
      
      EVENTS: {
        LIST: STATE.LIST,
        ADD: STATE.ADD,
        EDIT: STATE.EDIT,
      },
    },
    
    PRAYERS: {
      LIST: STATE.LIST,
      ADD: STATE.ADD,
    }
  }
}


const addPathFunction = (obj, path = []) => {
  return new Proxy(obj, {
    get(target, property) {
      if (property === 'path') {
        return path
      }
      const value = target[property]
      if (typeof value === 'object' && value !== null) {
        return addPathFunction(value, path.concat(property))
      }
      return {
        value,
        path: path.concat(property)
      }
    }
  })
}

const VIEW = addPathFunction(VIEWS)


export {
  KEY,
  STATE,
  VIEW,
}