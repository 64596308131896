import {useEffect, useState} from 'react'
import useLocalStorage from '../../../hook/useLocalStorage'
import {Link, useHREF} from '../../../lib/HREFContext'
import {KEY, VIEW} from '../../../constants'
import {filterPattern} from '../../../lib/helpers'


export default function GenericList({
  editLink,
  queryKey,
  storageKey,
}) {
  const [allData, setAllData] = useLocalStorage(storageKey, [])
  
  const [data, setData] = useState([])
  const [filter, setFilter] = useState()
  const [shortcuts, setShortcuts] = useState([])
  
  
  const del = index => {
    const thisn = allData[index]
    
    const name = thisn?.name || 'This Item'
    
    const yes = window.confirm(`Are you sure you want to remove ${name}?`)
    
    if (yes) {
      setAllData(allData.filter((person, i) => i !== index))
    }
  }
  
  
  useEffect(() => {
    if (allData?.length) {
      const indexedTopics = allData.map((person, index) => ({ ...person, index: index }))
      
      if (filter?.length) {
        const pattern = filterPattern(filter)
        const filteredPeople = indexedTopics.filter(person => pattern.test(person.name))
        
        filteredPeople.sort((a, b) => a.name.localeCompare(b.name))
        
        setData(filteredPeople)
      } else {
        setData(indexedTopics.sort((a, b) => a.name.localeCompare(b.name)))
      }
    }
  }, [allData, filter])
  
  
  useEffect(() => {
    // grab the first letter of each word in each name
    const newShortcuts = []
    
    allData.forEach(item => {
      item?.name.split(' ').forEach(word => {
        const letter = word.charAt(0).toUpperCase()
        
        if (!newShortcuts.includes(letter)) newShortcuts.push(letter)
      })
    })
    
    newShortcuts.sort()
    
    setShortcuts(newShortcuts)
  }, [allData])
  
  
  return <div>
    <div>
      {shortcuts && shortcuts.map((shortcut, index) => 
        <button
        className='shortcut'
        key={index}
        onClick={()=>setFilter((filter?filter:'')+shortcut)}
        >
          {shortcut}
        </button>
      )}
      
      <sub> {filter} </sub>
      <button className='plain' onClick={e=>setFilter('')}>&#x1F504;</button>
    </div>
    
    <ul>
      {data && data.map((item, index) => <li key={index}>
        <Link
          to={editLink}
          query={{[queryKey]: item.index}}
        >
          {item.name}
        </Link>
        
        &nbsp;
        
        <button onClick={e=>del(item.index)}>&#10060;</button>
      </li>)}
    </ul>
  </div>
}