import {useEffect, useState} from 'react'
import { useHREF } from '../../../lib/HREFContext'
import useLocalStorage from '../../../hook/useLocalStorage'
import {KEY, VIEW} from '../../../constants'
import {v4 as uuid} from 'uuid'


export default function PersonEdit() {
  const {personIndex, goto} = useHREF()
  
  const [people, setPeople] = useLocalStorage(KEY.PEOPLE, [])
  
  const [person, setPerson] = useState()
  
  
  const update = e => {
    e.preventDefault()
    
    const form = e.target
    const formData = new FormData(form)
    const name = formData.get('name')
    
    const newTopics = [...people]
    
    const priorTopic = newTopics[+personIndex]
    
    newTopics[+personIndex] = {
      id: uuid(),
      ...priorTopic,
      name,
      updated: (new Date()).toISOString(),
    }
    
    setPeople(newTopics)
    
    goto(VIEW.APP.PROXIMA.PEOPLE.path)
    
    return false
  }
  
  
  useEffect(() => {
    if ((+personIndex || +personIndex===0) && people?.length) {
      setPerson(people[+personIndex])
    }
  }, [personIndex, people])
  
  
  return <div>
    Person #{personIndex}
    
    <form onSubmit={update}>
      <label>
        Name
        <input autoFocus name="name" type="text" defaultValue={person?.name} />
      </label>
      
      <br />
      
      <button type="submit">Update</button>
    </form>
  </div>
}