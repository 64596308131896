import { useEffect, useState } from 'react'
import Add from './Add'
import List from './List'


export default function Prayers() {
  return <div>
    <Add />
    
    <List />
  </div>
}