import React from 'react'
import List from './List'
import {Link} from '../../../lib/HREFContext'


export default function GenericMain({
  addPath='',
  editLink,
  queryKey,
  storageKey,
  title,
}) {
  return <div>
    <h1> {title} </h1>
    
    <div>
      <Link to={addPath}>Add</Link>
    </div>
    
    <br />
    
    <List
      editLink={editLink}
      queryKey={queryKey}
      storageKey={storageKey}
    />
  </div>
}