import React from 'react'
import Prayer from './Prayer'
import useLocalStorage from '../../hook/useLocalStorage'
import {KEY} from '../../constants'


export default function PrayerList() {
  const [prayers, setPrayers] = useLocalStorage(KEY.PRAYERS, [])
  
  
  const deletePrayer = index=>{
    const yes = window.confirm('Are you sure you want to delete this prayer?')
    
    const newPrayers = [...prayers]
    
    newPrayers.splice(index, 1)
    
    setPrayers(newPrayers)
  }
  
  
  const sortByDate = (a, b) => {
    return new Date(b.created) - new Date(a.created)
  }
  
  
  return <div>
    <table>
      <thead>
        <tr>
          <th>People</th>
          <th>Topics</th>
          <th>Events</th>
          <th></th>
        </tr>
      </thead>
      
      <tbody>
        {prayers
          .sort(sortByDate)
          .map((prayer, index) => 
          <Prayer
            data={prayer} 
            index={index}
            key={index}
            onDelete={deletePrayer}
          />
        )}
      </tbody>
    </table>
  </div>
}
