import React from 'react'
import useLocalStorage from '../../hook/useLocalStorage'
import {KEY} from '../../constants'


export default function Prayer({
  data,
  onDelete,
  index,
}) {
  const [people] = useLocalStorage(KEY.PEOPLE, [])
  const [topics] = useLocalStorage(KEY.TOPICS, [])
  const [events] = useLocalStorage(KEY.EVENTS, [])
  
  
  const del = event=>{
    try {
      onDelete(index)
    } catch (error) {
      console.error(error)
    }
  }
  
  
  return <tr className='prayer viz'>
    <td>
      {data?.people?.map((id, index) => 
        <div key={index}>
          {people.find(el=>el.id===id)?.name}
        </div>
      )}
    </td>
    
    <td>
      {data?.topics?.map((id, index) => 
        <div key={index}>
        {topics.find(el=>el.id===id)?.name}
        </div>
      )}
    </td>
    
    <td>
      {data?.events?.map((id, index) => 
        <div key={index}>
        {events.find(el=>el.id===id)?.name}
        </div>
      )}
    </td>
    
    <td>
      <button
        onClick={del}
      >&#10060;</button>
    </td>
  </tr>
}
