import useLocalStorage from '../../../hook/useLocalStorage'
import {v4 as uuid} from 'uuid'


export default function GenericAdd({
  storageKey,
}) {
  const [data, setData] = useLocalStorage(storageKey, [])
  
  
  const add = (event) => {
    event.preventDefault()
    
    const form = event.target
    
    const formData = new FormData(form)
    
    const values = Object.fromEntries(formData)
    
    const newThing = {
      created: (new Date()).toISOString(),
      id: uuid(),
      name: values.name,
    }
    
    if (data) setData([
      ...data,
      newThing
    ])
    else setData([newThing])
    
    form.reset()
    
    return false
  }
  
  
  return <div>
    <form onSubmit={add}>
      Add
      <br />
      <label>
        Name
        <input autoFocus name="name" type="text" />
      </label>
      <br />
      <button type="submit">Add</button>
    </form>
  </div>
}