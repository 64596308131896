import React from 'react'
import {Link} from '../lib/HREFContext'
import {VIEW} from '../constants'
import DownloadButton from './DownloadButton'
import {useHREF} from '../lib/HREFContext'


export default function Footer() {
  const {goto} = useHREF()
  
  
  return <footer>
    <div>
      <Link to={VIEW.APP.PROXIMA.PEOPLE.path}>People</Link>
      
      <Link to={VIEW.APP.PROXIMA.TOPICS.path}>Topics</Link>
      
      <Link to={VIEW.APP.PROXIMA.EVENTS.path}>Events</Link>
    </div>
    
    <Link to={VIEW.APP.PRAYERS.path}>Pray</Link>
    
    <div>
      <DownloadButton />
      
      <button onClick={e=>goto(VIEW.APP.DATA.path)}>Data</button>
    </div>
  </footer>
}