import {useEffect, useState} from 'react'
import { useHREF } from '../../../lib/HREFContext'
import useLocalStorage from '../../../hook/useLocalStorage'
import {KEY, VIEW} from '../../../constants'
import {v4 as uuid} from 'uuid'


export default function Edit() {
  const {topicIndex, goto} = useHREF()
  
  const [topics, setTopics] = useLocalStorage(KEY.TOPICS, [])
  
  const [topic, setTopic] = useState()
  
  
  const update = e => {
    e.preventDefault()
    
    const form = e.target
    const formData = new FormData(form)
    const name = formData.get('name')
    
    const newTopics = [...topics]
    
    const priorTopic = newTopics[+topicIndex]
    
    newTopics[+topicIndex] = {
      id: uuid(),
      ...priorTopic,
      name,
      updated: (new Date()).toISOString(),
    }
    
    setTopics(newTopics)
    
    goto(VIEW.APP.PROXIMA.TOPICS.path)
    
    return false
  }
  
  
  useEffect(() => {
    if ((+topicIndex || +topicIndex===0) && topics?.length) {
      setTopic(topics[+topicIndex])
    }
  }, [topicIndex, topics])
  
  
  return <div>
    Topic #{topicIndex}
    
    <form onSubmit={update}>
      <label>
        Name
        <input autoFocus name="name" type="text" defaultValue={topic?.name} />
      </label>
      <br />
      <button type="submit">Update</button>
    </form>
  </div>
}