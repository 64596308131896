import Main from './Main'
import Add from './Add'
import List from './List'


const def = {
  Add,
  List,
  Main,
}


export default def